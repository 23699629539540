<template>
  <div class="l-privacy">
    <div class="l-privacy__ctn">
      <router-link to="/"
                   class="button-basic-orange"
                   style="padding: 10px 20px; width: 75px; width: fit-content;"
      >
        Back
      </router-link>

      <h2>Terms of Service</h2>

      <p>
        Welcome to  https://blockspingaming.com  (the “Platform”).
      </p>
      <p>
        The Platform is operated by Dragon Dream Digital Ltd, a Limited Company registered in Costa Rica under the number:
        3-102-848701 (the “BSG”, “DDD”, “Company”, “we” “our” or “us”).
      </p>
      <p>
        The Terms of Service ("Terms") constitutes a binding legal agreement between you and us as well as governing
        the relationship between you and us. We may terminate these Terms at our discretion at any time.
      </p>
      <p>
        The Platform and its services/products are only for personal entertainment purposes. The Platform is not
        an online or mobile casino.  Company does not offer any real money casino games or gambling services on the Platform.
      </p>

      <p>
        The Platform does accept real money, You can’t win actual money by playing the games offered by the Platform.
        Even though the Platform may offer the opportunity to purchase virtual items with real money or tokens. You may
        use the Platform if you are over 18 years of age. We may require proof that you meet this requirement for age.
      </p>

      <p>
        Please review the Terms carefully. If you do not agree to the Terms you should immediately stop using the Platform.
      </p>
      <ul>
        <h3>
          1. Changes to the Terms and updates
        </h3>
      </ul>

      <p>
        1.1. BSG may amend or modify  the Terms from time to time and any changes made shall come into effect after being
        published on the Platform. It remains your responsibility to ensure that you are aware of the correct, current
        Terms and we advise you to check the Terms on a regular basis. By continuing to use the Platform after a change
        or modification of the Terms, you accept the revised Terms.
      </p>
      <p>
        1.2. The Platform may have limited and varied compatibility across different devices, platforms and operating
        systems. You shall verify compatibility requirements.
      </p>
      <p>
        1.3. BSG may update the Platform with or without notifying you. You may need to update third party software from
        time to time in order to receive services and play games.
      </p>
      <ul>
        <h3>
          2. Account
        </h3>
      </ul>
      <p>
        2.1. In order for you to be able to play our games, you must first personally register an account ("Account").
        You must enter all mandatory information requested into the registration form. As part of the registration process,
        you will have to choose a username and password or allow us to access your account information from social media
        for your login into Platform.
      </p>
      <p>
        2.2. You can also  create an Account via a social media account, like Facebook or through a blockchain wallet.
      </p>
      <p>
        2.3. You understand that your user ID number, name and profile picture will be publicly available and that search
        engines may index your name and profile picture.
      </p>
      <p>
        2.4. BSG reserves the right to remove or reclaim any usernames at any time and for any reason, including but not
        limited to claims by a third party that a username violates a third party's rights.
      </p>
      <ul>
        <h3>
          3. User obligations
        </h3>
      </ul>

      <ul>
        <li>
          You guarantee the information you provide is true, complete and correct.
        </li>
        <li>
          You shall not disclose your login details to anyone. It is your sole responsibility to ensure that your login
          details are kept securely;
        </li>
        <li>
          In the event of any breach of security, or unauthorized access to your Account, you must immediately inform us
          and modify your login information if appropriate;
        </li>
        <li>
          It is your responsibility for anything that happens through your Account, whether or not such actions were taken
          by you.
        </li>
        <li>
          You agree to monitor your Account to restrict use by persons 16 years old. You are responsible for any unauthorized
          use of the Platform by minors.
        </li>
        <li>
          You agree to comply with all applicable export and sanction laws and regulations (“Trade Controls”).  You may
          not transfer or use any portion of the Platform or any related information or materials, unless otherwise
          provided by Trade Controls. Platform is not offered to individuals or entities subject to global sanctions
          by the United States or European Union. By using the Platform, you represent and warrant that you are not a
          subject of (including, but not limited: Cuba, Iran, Syria, North Korea, and the Crimea, Donetsk and Luhansk
          regions of Ukraine).
        </li>
        <li>
          You must have equipment and Internet access services to use the Platform. We shall have no liability whatsoever
          for any outages, slowness, capacity constraints or other deficiencies affecting the same.
        </li>
        <li>
          You are allowed to have only one Account.
        </li>
        <li>
          You shall not create an Account on behalf of someone other than yourself.
        </li>
        <li>
          You may not allow individuals whose Accounts have been terminated by us to use your Account.
        </li>
        <li>
          You shall not have an Account or use the Platform if you have previously been blocked, suspended or closed  by us.
        </li>
        <li>
          You shall not play multiple games or more than 1 session at the same time (including, but not limited to, opening
          multiple windows or tabs to play with the same account, playing in multiple devices at the same time with the same account).
        </li>
      </ul>
      <p>
        To the extent permissible under applicable law, we are not liable for any loss or damage arising from your failure to comply with any of the foregoing obligations.
      </p>
      <ul>
        <h3>
          4. Intellectual property
        </h3>
      </ul>
      <p>
        4.1. BSG and its licensors own all software, data, written materials and other content, graphics, forms, artwork, images, pictures, graphics, photographs, functional components, animations, videos, music, audio, text and any software concepts and documentation and other material on, in or made available through the Platform.
      </p>
      <p>
        4.2. BSG grants you a personal, non-exclusive, non-transferable, non-sub licensable, revocable, limited scope license to access and use the Platform using a  supported web browser or mobile device. Use of the Platform shall be solely for your own, private, non-commercial entertainment purposes.
      </p>
      <p>
        4.3. You hereby acknowledge that by using the Platform, you obtain no other party any right, title or interest in the Platform.
      </p>
      <p>
        4.4. If the Platform or any part thereof is determined to be illegal under the laws of the country in which you are located, you shall not be granted any license to use the Platform.
      </p>
      <ul>
        <h3>
          5. User content
        </h3>
      </ul>

      <p>
        5.1. We may permit you to publish messages, posts, comments, images, sounds, and all the material, data, text, graphics, photographs, videos, location information, voice, likeness,  and game records, or any other content  through the Platform (“User Content”).
      </p>
      <p>
        5.2. You agree that you are publishing such content willingly and you represent that you own such content, that you have all rights to publish said content and that the publishing of the content complies with all applicable laws.
      </p>
      <p>
        5.3. You are entirely responsible for User Content and  Company shall have no liability to you with respect of the User Content and you hereby waive all claims against us in this regard.
      </p>
      <p>
        5.4. You agree Company has no obligation to monitor or review User Content.
      </p>
      <p>
        5.5. You agree that BSG may  edit or remove User Content  and you hereby waive any rights you may have if the User Content is altered or changed.
      </p>
      <p>
        5.6. By providing the User Content you grant the Company and its affiliates perpetual and irrevocable, worldwide, fully paid-up and royalty free, non-exclusive, unrestricted, unconditional, unlimited license to use, copy, perform exploit, distribute, reproduce, display, modify, add to, subtract from, translate, edit and create derivative works based upon the User Content or any portion thereof in any manner (including without limitation promotional and advertising purposes) and in any and all media now known or hereafter devised all without any compensation to you whatsoever. You may not distribute, sell, transfer or license User Content  in any manner, in any country, or on any social network, or other medium without prior written permission of the Company. You grant Company the right to act as an agent on your behalf as the Platform's operator.
      </p>
      <p>
        5.7. To the extent permitted by applicable laws,  you  agree to waive all moral rights to the User Content.
      </p>
      <p>
        5.8. No credit, approval or compensation is due to you for any such use of the User Content you may submit.
      </p>
      <p>
        5.9. The license you grant us to use User Content ends when you delete your User Content or you close your Account unless your User Content has been shared with others, and they have not deleted it. You understand and accept that removed content may persist in back-up copies for a reasonable period of time.
      </p>
      <p>
        5.10 You  represent and warrant that User Content is:
      </p>
      <ul>
        <li>
          Free of viruses, adware, spyware, worms or other malicious code;
        </li>
        <li>
          Accurate and not confidential;
        </li>
        <li>
          You  have a right to make available under any applicable laws  or contractual relationships;
        </li>
        <li>
          You have permission from any third party whose personal data or intellectual property is in the User Content.
        </li>
      </ul>
      <p>
        5.11. You agree not to engage in transmitting, uploading, posting, or otherwise making available on the Platform, User Content or any other content that:
      </p>

      <ul>
        <li>
          is sexually oriented, explicit or suggestive or exploit people in a sexual or violent manner;
        </li>
        <li>
          is armful, threatening, abusive, harassing, tortious, defamatory, insulting,   or racially, ethnically or otherwise objectionable;
        </li>
        <li>
          is promote the illegal use of alcohol, drugs, tobacco or any activities that may be construed as illegal.
        </li>
      </ul>
      <ul>
        <h3>
          6. Virtual Items
        </h3>
      </ul>
      <p>
        6.1. You are not required to make any purchase of any kind to use the Platform.  You may have an opportunity to earn or purchase Virtual Items for use in the Platform. Virtual Items include virtual coins, points, credits, bonuses or chips, virtual in-game items or NFTs collections.
      </p>
      <p>
        6.2. You may collect a certain number of Virtual Items when you log into the Platform at recurring time intervals. If you exhaust your supply of Virtual Items, you may purchase additional Virtual Items and continue to play the games through the Platform. Also, you may wait until additional free Virtual Items are available to you.
      </p>
      <p>
        6.3. Virtual Items are used under a license subject to the terms set out  below.
      </p>
      <ul>
        <li>
          Only legal residents of non-restricted countries may hold Virtual Items;
        </li>
        <li>
          We authorize purchase or acquisition  of Virtual Items only from us and through means we provide on the applicable website;
        </li>
        <li>
          Any purchases or transfers made outside of the Platform is prohibited.  We are not liable for any claims or damages caused to the users with respect to Virtual Items purchased or obtained from third parties, and not through the means provided within the game;
        </li>
        <li>
          You will have to pay a fee using fiat money, if you wish to purchase  Virtual Items.
        </li>
        <li>
          Purchased virtual items do not have an equivalent or substitute value in real world money from BSG or any other party;
        </li>
        <li>
          Company grants you a limited, personal, revocable, non-transferable, non-sublicense license to use the Virtual Items with the Platform. You can get a license by visiting the purchase page in one of our games, providing your billing information, confirming the particulars of your purchase and re-affirming your agreement to these Terms;
        </li>
        <li>
          You will have no right or title to any Virtual Items you acquire, or any other attributes associated with use of the Platform or stored within the Platform;
        </li>
        <li>
          You may not transfer, purchase, sell, or exchange Virtual Items outside the Platform unless otherwise expressly authorized by us in writing. Any such transfer or attempted transfer is a violation of these Terms and may result in termination of your Account or legal action taken against you;
        </li>
        <li>
          You may not sublicense, trade, sell or attempt to sell, exchange  Virtual Items for fiat money or value of any kind outside of a game, without our written permission.
        </li>
      </ul>
      <p>
        6.4. Company has the right:
      </p>
      <ul>
        <li>
          To manage, regulate, control, modify or eliminate Virtual Items at its sole discretion. We are not liable for the exercise of such rights;
        </li>
        <li>
          To change or revise prices and availability of Virtual Items without notice;
        </li>
        <li>
          To remove or revoke Virtual Items associated with your Account at our sole discretion;
        </li>
        <li>
          To refuse your request to purchase or acquire Virtual Items for any reason.
        </li>
      </ul>

      <ul>
        <h3>
          7. Privacy policy
        </h3>
      </ul>

      <p>
        At all times your information will be treated in accordance with our Privacy Policy, which is incorporated by reference into this Terms and can be viewed at: <router-link to="/privacy" class="c-footer__nav-list">
        {{ $t("general.privacy_policy") }}
      </router-link>
      </p>
      <ul>
        <h3>
          8. Disclaimer. Limitation of liability
        </h3>
      </ul>

      <p>
        8.1. Platform is provided "as is". You agree that you access or use the Platform, in whole and in part, at your own risk.
      </p>
      <p>
        8.2. As far as is permissible under law, BSG, affiliates or subsidiaries, or any of their its officers, directors, employees, contractors disclaim all warranties, conditions or other terms of any kind, either explicit or implied, in connection with the Platform and your use thereof, including, but not limited to, fitness for a  particular purpose,  implied warranties of merchantability, title, non-infringement, usefulness, authority, accuracy, completeness, and timeliness.
      </p>
      <p>
        8.3. BSG makes no warranties or representations and assume no liability or responsibility for any:
      </p>
      <ul>
        <li>
          Quality, accuracy, completeness and validity of any information or materials in connection with the Platform;
        </li>
        <li>
          Transmission through the Platform of any bugs, viruses, harmful components, trojan horses by any third party;
        </li>
        <li>
          Errors, defects, mistakes, or inaccuracies of content of the Platform  and any sites linked to the Platform;
        </li>
        <li>
          Any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the Platform;
        </li>
        <li>
          Any interruption or cessation of transmission to or from the Platform;
        </li>
        <li>
          Personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the Platform;
        </li>
        <li>
          Any unauthorized access to or use of our's secure servers or any and all personal information or financial information stored therein;
        </li>
        <li>
          Errors, failures or delays in computer system, phone line, hardware, software or program malfunctions, network connections on account of your access to or use of the Platform.
        </li>
      </ul>

      <p>
        8.4. In the event of disruption of the Platform that does not allow you to complete a game in which you have begun to participate, we may allow users to participate again in a game until the participation is complete. BSG has the right to limit your game play or terminate your participation in the Platform if we determine on our sole discretion that you have intentionally caused such a disruption. In case of such error or disruption, BSG  has the right to adjust the amount of credits or coins.
      </p>
      <p>
        8.5. We are not liable for any potential winnings or other lost opportunity from any unfinished game or a game malfunction or error of any kind.  The games offered through the Platform  do not replicate the odds of winning or the payouts of similar games found in real casinos.
      </p>
      <p>
        8.6. Unauthorized attempts to gain access to the Platform, interfere with procedures or performance of the Platform, or deliberately damage or undermine the Platform will result in  civil or criminal prosecution and termination of your Account and forfeiture of all accumulated rewards.
      </p>
      <p>
        8.7. Any attempt to access or use any portion of the Platform by means of automatic, macro, programmed, or to otherwise commit fraud with regard to the Platform, is subject to  civil or criminal prosecution, termination of your Account, and forfeiture of all rewards.
      </p>
      <p>
        8.8. As far as is permissible under law, BSG, its affiliates or subsidiaries, or any of their directors, officers, agents, contractors, partners and employees, are not liable to you or any third party for:
      </p>
      <ul>
        <li>
          Any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the Platform;
        </li>
        <li>
          Any damages or any other losses, costs, or expenses of any kind arising, directly or indirectly, from your reliance on, access to, use of, or inability to access or use, the Platform or through your downloading of any materials, data, text, images, video or audio from the Platform;
        </li>
        <li>
          The conduct of third parties, including other users of the Platform and operators of external sites.
        </li>
      </ul>
      <p>
        8.9. As far as is permissible under law, BSG, its affiliates or subsidiaries, or any of their directors, officers, agents, contractors, partners and employees will not liable to you for more than the amount you have paid us in 90 days immediately preceding the date on which you first assert any such claim.
      </p>
      <p>
        8.10 You  agree that if you have not paid BSG any amounts in 90 days immediately preceding the date on which you first assert any such claim, your exclusive remedy for any dispute with BSG is to stop using the Platform and to terminate your account.
      </p>
      <ul>
        <h3>
          9. Termination
        </h3>
      </ul>

      <p>
        9.1. You may stop using the Platform at any time and may request to delete your Account  at any time by contacting us at info@blockspingaming. We don't provide  refunds, benefits or other compensation if you request deletion of your Account. Potential refunds for Virtual Items as described below remain unaffected.
      </p>
      <p>
        9.2. At our sole discretion, we may suspend, terminate, modify or delete accounts; or terminate access to Platform or our games; delay or remove hosted content with no compensation for any losses.
      </p>
      <p>
        9.3. BSG may refuse access to the Platform or may limit, suspend, modify, delete or terminate your Account without notice if: you violate the Terms, illegal or improper use of your Account or Platform, our games or or Company's intellectual property in our sole discretion.
      </p>
      <p>
        9.4. We may delete or terminate accounts that are inactive for 180 days or longer.
      </p>
      <p>
        9.5. If you have more than one Account, we may terminate all of your Accounts.
      </p>
      <p>
        9.6. As a result of Account termination, you may lose your user name, password and all related information and files associated with your Account.  BSG is not responsible  for any damage that may result from the termination, and the Company is under no obligation to compensate you for any such losses or results.
      </p>
      <p>
        9.7. Any attempt by you to disrupt or interfere with the Platform including undermining or manipulating the legitimate operation of any BSG game is a violation of the Terms  and may be a violation of civil and criminal laws in your country.
      </p>
      <p>
        9.8. We  may selectively remove, revoke or modify Benefits associated with your Account.  "Benefits" mean any benefits, privileges, items, licensed rights granted, earned, awarded, gifted, provided to or purchased by you to access and use online or off-line elements or features of the Platform and products.
      </p>
      <p>
        9.9. In case of termination of your Account or removal of any Benefits, no refund will be granted, no Benefits
        will be credited to you or converted to cash or other forms of reimbursement. You will have no further access to
        your Account or Benefits associated with your Account.
      </p>
      <p>
        9.10. We are not obligated to provide you notice before suspending or terminating your Account or selectively
        removing, revoking or modifying Benefits associated with your Account.
      </p>
      <p>
        9.11. In the event of termination of your Account, you may use the Platform or create an Account only with the
        permission of BSG.
      </p>

      <ul>
        <h3>
          10. Governing Law and Jurisdiction.
        </h3>
      </ul>

      <p>
        10.1. These Terms and any issues, disputes or claims arising out of or in connection with use of Platform shall
        be governed by and construed in accordance with the laws of Cyprus without regard to conflict or
        choice-of-law principles.
      </p>
      <p>
        10.2. Except as otherwise required by applicable law or provided in this Terms, you and the Company accept the
        exclusive jurisdiction of the Cyprus courts.
      </p>
      <p>
        10.3. In the event there is a claim against the Company, you agree to exercise best efforts to resolve the
        dispute as soon as possible by contacting info@blockspingaming. If we have your contact information, we will
        undertake reasonable efforts to contact you to resolve any claim we may possess informally before taking any
        formal action. If we and you are unable to resolve the dispute through the informal procedure within 15 days,
        then you or BSG may invoke an arbitration proceeding as described in Section 11.
      </p>
      <ul>
        <h3>
          11. Arbitration
        </h3>
      </ul>
      <p>
        11.1. By agreeing to these Terms, you and BSG agree to resolve any disputed through final and binding
        arbitration as discussed herein, except as set forth under “Exceptions to Agreement To Arbitrate” below.
      </p>
      <p>
        11.2. If you don’t agree to arbitrate, you may opt out within 30 days of first agreeing to these Terms by
        contacting info@blockspingaming otherwise, you shall be bound to arbitrate disputes in accordance with this
        Terms. You must specify your first and last name. If you opt out of these arbitration provisions, we also will
        not be bound by them.
      </p>
      <p>
        11.3. You and the Company agree that the C.A.M.C (Cyprus Arbitration and Mediation Centre) will administer the
        arbitration, which shall be conducted in accordance with its rules in effect at the time arbitration is sought.
        The C.A.M.C rules will govern payment of all arbitration fees.
      </p>
      <p>
        11.4. Arbitration will proceed on an individual basis and will be handled by a sole arbitrator in accordance
        with those rules. Unless the Company and you agree otherwise, any arbitration hearings will take place in a
        reasonably convenient location for both parties with due consideration of their ability to travel and other
        pertinent circumstances. If the parties are unable to agree on a location, the determination shall be made by
        C.A.M.C.
      </p>
      <p>
        11.5. You and the BSG agree that the arbitration of any dispute shall proceed on an individual basis. The BSG or
        you may not bring a claim as a part of a class, group, collective, coordinated, consolidated or mass arbitration
        (“Collective Arbitration”). Any dispute against us will be deemed a Collective Arbitration if (a) 2 or more
        similar claims for arbitration are filed concurrently by or on behalf of one or more claimants; and (b) counsel
        for the claimants are the same, share fees or coordinate across the arbitrations.
      </p>
      <p>
        11.6. The BSG and you agree that any claims will be adjudicated on an individual basis and each waives the right
        to participate in Collective Arbitration, or other joint action with respect to the claims.
      </p>
      <p>
        11.7. Exceptions to Agreement to Arbitrate. BSG or you may bring a lawsuit in a court of law asserting causes of
        action which seek only temporary injunctive relief until an arbitrator can be empaneled and determine whether to
        continue, modify or terminate such relief, or to enforce any arbitral award issued hereunder. If you are
        resident of the United Kingdom or in the European Economic Area, you may also use the Online Dispute Resolution
        platform offered by the European Commission https://ec.europa.eu/consumers/odr/ .
      </p>
      <p>
        Please note that BSG shall not be required to use this or any other alternative dispute resolution platform.
      </p>
      <ul>
        <h3>
          12. General
        </h3>
      </ul>
      <p>
        12.1. This Terms constitutes the entire agreement between you and BSG relating to the subject matter hereof, and
        cancels all previous agreements, verbal or written or based on alleged past practices.
      </p>
      <p>
        12.2. If any provision(s) of the Terms shall be determined to be illegal or unenforceable, such determination
        shall in no manner affect the legality or enforceability of any other provision hereof.
      </p>
      <p>
        12.3. We may assign, sell, transfer, subcontract or sublet rights, or delegate responsibilities under the Terms,
        in whole or in part, without the prior written approval of you. You may not assign or delegate any rights or
        obligations under the Terms without our prior written consent
      </p>
      <p>
        12.4. We shall not be liable for any delay or failure to perform resulting from causes outside the reasonable
        control of the Company, such as acts of God, war, terrorism, riots, embargoes, acts of civil or military
        authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation
        facilities, fuel, energy, labor or materials.
      </p>
      <p>
        12.5. BSG makes no representations or warranties, implicit or explicit, as to your legal right to access or use
        the Platform nor shall any person affiliated, or claiming affiliation. Not all features, products or services
        provided by the Platform are available to all persons or in all geographic locations. BSG reserves the right to
        limit, in its sole discretion, the provision and quantity of any feature, product or service to any person or
        geographic area. You are solely responsible for complying with applicable local laws which you access the
        Platform and obeying those laws.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
  mounted() {
    const scrollTo = document.getElementById("header");
    if(scrollTo) {
      scrollTo.scrollIntoView();
    }
  }
};

</script>

<style lang="scss" scoped>
.l-privacy {
  background: #ffffff;

  min-height: 100vh;
  /* Background image is fixed in the viewport so that it doesn't move when
     the content's height is greater than the image's height */
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
h2{
  margin: 20px;
  font-family: 'Rubik', sans-serif;
  color: $black;
}
h3{
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  color: $black;
}

.l-privacy__ctn {
  font-family: 'Rubik', sans-serif;
  position: relative;
  width: 800px;
  margin: 0 auto;
  font-size: 16px;
  background-color: $white;
  padding: 7rem 4rem;
  margin-bottom: 3rem;
  border-radius: 6px;
  color: $black;
  font-weight: 400;

  p {
    margin-bottom: 16px;
  }


  ul {
    list-style: disc;
    margin-bottom: 12px;
    margin-left: 30px;
  }

  @include media-max(820px) {
    width: 95%;
  }
}

.l-privacy__back-btn {
  position: absolute;
  top: 20px;
  left: 4.5vw;
  z-index: 15;
}
</style>